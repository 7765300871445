import {
  Organization,
  Title,
  type TitleFollowsEntity,
  type TitleReadingStatus,
  User,
} from "~/src/api";
import { debounce } from "perfect-debounce";
import { createAsyncResource } from "./async/asyncResource";

export function useFollow(
  type: MaybeRef<"organization" | "user">,
  id: MaybeRef<string>,
) {
  const isLoggedIn = useIsLoggedIn();
  const nuxtApp = useNuxtApp();
  const app = nuxtApp.$app();
  const userOauthData = useLoggedOAuthUserData();
  const loggedUserId = computed(() => userOauthData.value?.profile.sub ?? "");

  const initialFollowAsyncResource = createAsyncResource<boolean>(
    async (type: string, id: string, loggedUserId: string) => {
      if (!loggedUserId || !id) return false;

      switch (type) {
        case "organization":
          return !!(await Organization.getFollow(
            id,
            [],
            await getTokenOrThrow(),
          ));
        case "user":
          return !!(await User.checkIfUserFollowsUser(loggedUserId, id));
        default:
          return false;
      }
    },
    type,
    id,
    loggedUserId,
  );

  const currentState = ref(false);
  const isFollowing = ref(false);

  const initialFollowData = computed(
    () => initialFollowAsyncResource.value.data.value,
  );
  const initialFollowPending = computed(
    () => initialFollowAsyncResource.value.pending.value,
  );

  watch(initialFollowData, (newFollowData) => {
    currentState.value = !!newFollowData;
    isFollowing.value = !!newFollowData;
  });

  const { action: toggleFollow, pending: toggleFollowPending } = useAction2(
    async () => {
      if (!isLoggedIn.value) return;
      if (currentState.value === isFollowing.value) return;

      const typeValue = unref(type);
      const idValue = unref(id);
      if (!idValue.length) return;

      currentState.value = isFollowing.value;

      if (currentState.value) {
        switch (typeValue) {
          case "organization":
            return await Organization.follow(idValue, await getTokenOrThrow());
          case "user":
            return await User.follow(idValue, await getTokenOrThrow());
        }
      } else {
        switch (typeValue) {
          case "organization":
            return await Organization.unfollow(
              idValue,
              await getTokenOrThrow(),
            );
          case "user":
            return await User.unfollow(idValue, await getTokenOrThrow());
        }
      }
    },
  );

  const debouncedToggleFollow = debounce(toggleFollow, 300);

  return {
    isFollowing,
    initialFollowPending,
    toggleFollowPending,
    toggleFollow: () => {
      if (isLoggedIn.value) {
        isFollowing.value = !isFollowing.value;
        debouncedToggleFollow();
      } else app?.openLoginRequiredModal();
    },
  };
}

const mockTitleFollowManager = () => ({
  initialFollowPending: ref(true),
  status: ref(null),
  notification: ref(false),
  setStatus: (value: TitleReadingStatus | null) => {},
  setNotification: (value: boolean) => {},
  updateFollow: (status: TitleReadingStatus | null, notif: boolean) => {},
  pending: ref(true),
});

export function useTitleFollow(titleId: string, onError?: (err: any) => any) {
  const authStore = useNuxtApp().$auth();
  const getToken = async () => (await authStore?.getToken()) || null;

  const followData = useState<{
    status: TitleReadingStatus | null;
    notification: boolean;
    lastEntity: TitleFollowsEntity | null;
    firstFetched: boolean;
    initialFetchPending: boolean;
    pending: boolean;
  }>(`user-follows-title-${titleId}-data`, () => ({
    status: null,
    notification: false,
    lastEntity: null,
    firstFetched: false,
    initialFetchPending: true,
    pending: false,
  }));

  const initialize = async () => {
    if (!process.client) return;
    if (followData.value.firstFetched || followData.value.pending) return;
    console.debug("attempting to initialize title follow for", titleId);

    followData.value.pending = true;

    await authStore?.waitUntilRefreshIsComplete();
    const token = await getToken();

    // silent fail here due to no auth, but still mark initial as resolved but not fetched
    if (!token) {
      followData.value.initialFetchPending = false;
      followData.value.pending = false;
      return;
    }

    const follow = await Title.getFollow(titleId, [], token);

    // now we mark this as fetched since we actually made a request to the endpoint
    followData.value.firstFetched = true;
    followData.value.initialFetchPending = false;
    followData.value.pending = false;
    if (!follow) return;

    // initialize data if any
    followData.value.status = follow.attributes.readingStatus;
    followData.value.notification = follow.attributes.notification;
    followData.value.lastEntity = follow;
  };

  // fetch the status if not initialized
  if (!followData.value.initialFetchPending) initialize();

  // update functions
  async function updateFollow(
    status: TitleReadingStatus | null,
    notification: boolean,
  ) {
    if (followData.value.pending || followData.value.initialFetchPending)
      return;
    if (
      followData.value.status === status &&
      followData.value.notification === notification
    )
      return;

    followData.value.pending = true;
    const token = await getTokenOrPrompt().catch((e) => {
      followData.value.pending = false;
      throw e;
    });

    // unfollow the title with unset status
    if (!status) {
      const prevStatus = followData.value.status;

      try {
        followData.value.status = null;

        await Title.unfollow(titleId, token);

        followData.value.notification = false;
        followData.value.lastEntity = null;
        followData.value.pending = false;
      } catch (e) {
        onError?.(e);
        followData.value.status = prevStatus;
      }

      return;
    }

    const prev = {
      status: followData.value.status,
      notification: followData.value.notification,
    };

    try {
      // immediately update visually
      followData.value.status = status;
      followData.value.notification = notification;

      // update or follow the title
      const follow = await Title.follow(
        titleId,
        {
          readingStatus: status,
          notification: notification.toString() as "true" | "false",
        },
        token,
      );

      // update with real values
      followData.value.status = follow.attributes.readingStatus;
      followData.value.notification = follow.attributes.notification;
      followData.value.lastEntity = follow;
    } catch (e) {
      onError?.(e);

      // revert immediate change if error
      followData.value.status = prev.status;
      followData.value.notification = prev.notification;
    }

    followData.value.pending = false;
  }

  async function updateStatus(status: TitleReadingStatus | null) {
    // TODO: subject to change, we auto turn on and off notifictaions around reading
    return updateFollow(status, status === "reading");
  }

  async function updateNotification(notification: boolean) {
    return updateFollow(followData.value.status, notification);
  }

  return {
    // expose this for ssr/csr shenanigans
    initialize,

    followData,
    updateFollow,
    updateStatus,
    updateNotification,
  };
}
